import tw, { styled } from "twin.macro"

import { StyledButton } from "../../Styled/Button"
import { StyledContainer } from "../../Styled/Container"

export const Wrapper = styled(StyledContainer)`
  ${tw`pt-3-2 pb-4 md:py-8 flex flex-wrap`}
`

export const ImageWrapper = tw.div`
  md:w-1/2 hidden md:block min-h-70
`

export const ContentWrapper = tw.div`
  md:w-1/2 w-full flex items-center
`

export const Content = tw.div`
  max-w-41 mx-auto md:py-7 md:pl-2
`

export const Title = tw.h1`
  text-28 leading-1.28 tracking-10 font-bold uppercase mb-4 text-center mx-2-4 
`

export const Description = tw.p`
  text-14 leading-1.78 mb-2 text-center
`

export const Submit = styled(StyledButton)`
  ${tw`mt-2-2 mb-2`}
`

export const Errors = tw.ul`
  pt-0-4
`

export const Error = tw.li`
  leading-1.5 text-12 text-red list-none
`

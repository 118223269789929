import React from "react"

import { withReset } from "./withReset"
import { ResetForm } from "./Form/ResetForm"
import { Layout } from "../Layout/Layout"

export const Reset = withReset(
  ({ title, image }): JSX.Element => (
    <Layout title={title} image={image}>
      <ResetForm />
    </Layout>
  )
)

import React, { useMemo } from "react"
import { graphql, useStaticQuery } from "gatsby"

import { useApp } from "../../../../hooks/useApp"
import { useCustomerAccount } from "../../../../hooks/useCustomer"
import { useRoutes } from "../../../../hooks/useRoutes"

export const withResetForm = Component => ({ name = "AccountResetForm" }) => {
  const {
    config: {
      settings: { params },
    },
  } = useApp()
  const { resetCustomer, data, setData, loading, errors } = useCustomerAccount()
  const { getUrlParameter } = useRoutes()

  const { reset } = useStaticQuery(graphql`
    query SANITY_PAGE_ACCOUNT_RESET {
      reset: sanityPageAccountReset {
        description
        errorMessage
        additionalSubmitButton
        additionalPasswordLabel
        additionalConfirmPasswordLabel
      }
    }
  `)

  const {
    description,
    errorMessage,
    additionalSubmitButton,
    additionalPasswordLabel,
    additionalConfirmPasswordLabel,
  } = reset || {}

  const { customerId, resetToken, initError } = useMemo(() => {
    const resetParams = getUrlParameter(params?.customer)?.split(`/`) || []

    const customerId = resetParams?.[0] || null
    const resetToken = resetParams?.[1] || null

    let initError = null

    if (!customerId || !resetToken) {
      initError =
        "Your reset password link is invalid. Please click the link in your email again."
    }

    return { customerId, resetToken, initError }
  }, [getUrlParameter, params?.customer])

  const handleSubmit = async event => {
    event.preventDefault()
    await resetCustomer(customerId, resetToken, data?.password)
  }

  const handleChange = ({ target: { type, name, value, checked } }) => {
    setData(prevState => ({
      ...prevState,
      [name]: type === "checkbox" ? checked : value,
    }))
  }

  const isInvalid = data.password !== data.confirmedPassword

  Component.displayName = name
  return (
    <Component
      data={data}
      handleChange={handleChange}
      handleSubmit={handleSubmit}
      loading={loading}
      initError={initError}
      errors={errors}
      isInvalid={isInvalid}
      description={description}
      errorMessage={errorMessage}
      additionalSubmitButton={additionalSubmitButton}
      additionalPasswordLabel={additionalPasswordLabel}
      additionalConfirmPasswordLabel={additionalConfirmPasswordLabel}
    />
  )
}

import React from "react"

import { Wrapper, ImageWrapper, ContentWrapper, Content, Title } from "./LayoutStyles"
import { Image } from "../../Image/Image"

export const Layout = ({ title, image, children = null }) => {
  return (
    <Wrapper>
      <ImageWrapper>
        <Image image={image} fullHeight />
      </ImageWrapper>
      <ContentWrapper>
        <Content>
          <Title>{title}</Title>
          {children}
        </Content>
      </ContentWrapper>
    </Wrapper>
  )
}
